export default {
    apiGateway: {
        REGION: "us-east-1",
        URL: "https://191ni3jzul.execute-api.us-east-1.amazonaws.com/dev"
    },
    cognito: {
        REGION: "us-east-1",
        USER_POOL_ID: "us-east-1_r68UH2ctt",
        APP_CLIENT_ID: "3glj3pd551mek75pt612q4dm0g",
        IDENTITY_POOL_ID: "us-east-1:39530dbb-145f-4474-ac0d-9889180c38c3"
    }
};