import React, { Component } from "react";
import "./Home.css";

export default class Home extends Component {
    render() {
        return (
            <div className="Home">
                <div className="lander">
                    <h1>hax.ee</h1>
                    <p>A simple goals app - lifehax</p>
                </div>
            </div>
        );
    }
}